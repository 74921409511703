import axios from 'axios';

export default class WebLocalStorage {
    init () {
      return new Promise((resolve) => {
        resolve('success');
      })
    }
    async destroy () {
        await this.dataPartai().reset()
        await this.dataSuara().reset()
        await this.dataSummarySuara().reset()
        await this.lastVerificationWork().reset()
        return Promise.resolve('success')
    }
    dataPartai () {
        return {
            init: async () => {
                let all = await this.dataPartai().get()
                if (!all) {
                    try {
                        const resp = await axios.get(process.env.VUE_APP_API_URL + 'parties?limit=30&order=sort&ascending=1');
                        await this.dataPartai().save(resp.data.data)
                        all = resp.data.data
                    } catch (error) {
                        Promise.reject(error)
                    }
                }
                return Promise.resolve(all)
            },
            get: () => {
                return new Promise((resolve) => {
                    const item = localStorage.getItem('pilkada_data_partai');
                    if (item) {
                        return resolve(JSON.parse(item));
                    }
                    return resolve(null);
                })
            },
            save: async (data) => {
                localStorage.setItem('pilkada_data_partai', JSON.stringify(data));
                return Promise.resolve(data)
            },
            reset: async () => {
                localStorage.removeItem('pilkada_data_partai');
                return Promise.resolve('success')
            }
        }
    }
    lastVerificationWork () {
        return {
            get: () => {
                return new Promise((resolve) => {
                    const item = localStorage.getItem('pilkada_last_verification_work');
                    if (item) {
                        return resolve(JSON.parse(item));
                    }
                    return resolve(null);
                })
            },
            save: async (data) => {
                localStorage.setItem('pilkada_last_verification_work', JSON.stringify(data));
                return Promise.resolve(data)
            },
            reset: async () => {
                localStorage.removeItem('pilkada_last_verification_work');
                return Promise.resolve('success')
            }
        }
    }
    dataSummarySuara () {
        return {
            // init: async (data) => {
            //     let all = await this.dataSummarySuara().get()
            //     if (!all) {
            //         await this.dataSummarySuara().save(data)
            //         all = await this.dataSummarySuara().get(data)
            //     }
            //     return Promise.resolve(all)
            // },
            get: () => {
                return new Promise((resolve) => {
                    const item = localStorage.getItem('pilkada_data_summary_suara');
                    if (item) {
                        return resolve(JSON.parse(item));
                    }
                    return resolve(null);
                })
            },
            save: async (data) => {
                localStorage.setItem('pilkada_data_summary_suara', JSON.stringify(data));
                return Promise.resolve(data)
            },
            reset: async () => {
                localStorage.removeItem('pilkada_data_summary_suara');
                return Promise.resolve('success')
            }
        }
    }
    dataSuara () {
        return {
            init: async (regionId, level) => {
                let all = await this.dataSuara().get()
                if (!all) {
                    const item = {
                        region_id: regionId,
                        // party_vote_count: 0,
                        level: level,
                        votes_data: [],
                    };
                    await this.dataSuara().saveAll(item)
                    all = item
                }
                return Promise.resolve(all)
            },
            get: () => {
                return new Promise((resolve) => {
                    const item = localStorage.getItem('pilkada_form_suara');
                    if (item) {
                        return resolve(JSON.parse(item));
                    }
                    return resolve(null);
                })
            },
            saveByParty: async (partyId, voteData, partyVoteCount = 0) => {
                const all = await this.dataSuara().get()
                if (!all) {
                    return Promise.reject('data is null please init first');
                }
                const findTheIndex = all.votes_data.findIndex(vote_data => {
                    return vote_data.party_id === partyId
                })
                const formData = []
                voteData.forEach(d => {
                    formData.push({
                        id: d.id,
                        name: d.name,
                        number: d.number,
                        vote_count: d.vote_count
                    });
                });
                if (findTheIndex < 0) {
                    all.votes_data.push({
                        party_id: partyId,
                        party_vote_count: partyVoteCount,
                        votes_data: formData
                    })
                } else {
                    all.votes_data[findTheIndex].votes_data = formData
                }
                // if (partyVoteCount) {
                //     all.party_vote_count = partyVoteCount;
                // }
                await this.dataSuara().saveAll(all)
                return Promise.resolve(all)
            },
            saveAll: async (data) => {
                localStorage.setItem('pilkada_form_suara', JSON.stringify(data));
                return Promise.resolve(data)
            },
            reset: async () => {
                localStorage.removeItem('pilkada_form_suara');
                return Promise.resolve('success')
            }
            // getByPartyId: async () => {
            //     await this.get()
            //     return new Promise((resolve) => {
            //         const item = localStorage.getItem('pilkada_form_suara');
            //         if (item) {
            //             return resolve(JSON.parse(item));
            //         }
            //         return resolve(null);
            //     })
            // },
            // save: (regionId, votesData) => {
            //     return new Promise((resolve) => {
            //         const item = {
            //             region_id: regionId,
            //             votes_data: votesData,
            //         };
            //         localStorage.setItem('pilkada_form_suara', item);
            //         return resolve(item);
            //     })
            // }
        }
    }
}
